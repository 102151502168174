import React from "react";
import Remito from "../components/Remito";
// import GetDataInvoice from "../report/GetDataInvoice";
// import GetNrodeComanda from "../components/GetNrodeComanda";
import Footer from "../components/Footer";
import "../css/footer.css";

const Remitos = () => {
  return (
    <>
      {/* <GetNrodeComanda /> */}
      <Remito />
      {/* <GetDataInvoice /> */}
      <Footer />
    </>
  );
};

export default Remitos;
