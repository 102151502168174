import React from "react";
import ClientePrev from "../components/ClientePrev";
import Footer from "../components/Footer";
import EasyInvoiceSample from "../report/Invoice";

// import "../css/empresa.css";
import "../css/footer.css";

const Clientes = () => {
  return (
    <>
      
      {/* <EasyInvoiceSample /> */}
      <ClientePrev />
      <Footer />
    </>
  );
};

export default Clientes;
