import React from "react";
import AppHojaRutaReactTable from "../table/AppHojaRutaReactTable";

import Footer from "../components/Footer";

const HojaRuta = () => {
  return (
    <>
      <AppHojaRutaReactTable />
      {/* <TableComandas /> */}
      {/* <Footer /> */}
    </>
  );
};

export default HojaRuta;
