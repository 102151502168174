import React from "react";
import Login from "./pages/Login";

const Applogin = () => {
  return (
    <>
      <Login />
    </>
  );
};

export default Applogin;
