import React from "react";
import AppGestionReactTable from "../table/AppGestionReactTable";
import Footer from "../components/Footer";
import "../css/footer.css";

const Comandas = () => {
  return (
    <>
      <AppGestionReactTable />
      <Footer />
    </>
  );
};

export default Comandas;
