import React from "react";
import Empresa from "../components/Empresa";
import Footer from "../components/Footer";

// import "../css/empresa.css";
import "../css/footer.css";

const Empresas = () => {
  return (
    <>
      <Empresa />
      <Footer />
    </>
  );
};

export default Empresas;
