import React from "react";
import AppOrdenAPrepararReactTable from "../table/AppOrdenAPrepararReactTable";

import Footer from "../components/Footer";

const OrdenAPreparar = () => {
  return (
    <>
      <AppOrdenAPrepararReactTable />
      {/* <TableComandas /> */}
      {/* <Footer /> */}
    </>
  );
};

export default OrdenAPreparar;
